import * as MasterServices from "@/apiServices/MastersServices";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export function getPropByString(obj, propString) {
  if (!propString) return obj;

  var prop,
    props = propString.split(".");

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }

  return obj[props[i]];
}

export function convertToCurrency(val) {
  try {
    return Number(val).toLocaleString("en-US");
  } catch (error) {
    console.error("Error in convertToCurrency");
    return "";
  }
}

export function convertToIntArr(str) {
  if (str && typeof str === "string") {
    const arr = str.split(",");
    if (arr.length) {
      return arr.map((i) => parseInt(i)).filter((i) => i);
    }

    return [];
  }
  return [];
}

export function convertToStrArr(str) {
  try {
    if (str) {
      if (str.startsWith("[")) {
        return JSON.parse(str);
      }

      const arr = str.split(",");
      if (arr.length) {
        return arr;
      }
      return [];
    }
    return [];
  } catch (error) {
    console.error("Error in convertToStrArr ", error);
    return [];
  }
}

export function strNumToBoolean(str_num) {
  if (str_num && str_num == "1") {
    return true;
  }
  return false;
}

export async function uploadImages(e) {
  const files = e.target.files;
  if (!files || !files.length) return;

  const formData = new FormData();
  files.forEach((file) => {
    formData.append("images", file);
  });

  const response = await MasterServices.uploadImages(formData);

  if (!response.data.names || !response.data.names.length) return [];

  return response.data.names;
}

export async function uploadFiles(e) {
  const files = e.target.files;
  if (!files || !files.length) return;

  const formData = new FormData();
  for (const file of files) {
    formData.append("files", file);
  }

  const response = await MasterServices.uploadFiles(formData);

  if (!response.data.data.names || !response.data.data.names.length) return [];

  return response.data.data.names;
}

export async function uploadMultiFiles(files, onUploadProgress) {
  if (!files || !files.length) return;

  const formData = new FormData();
  for (const file of files) {
    formData.append("files", file);
  }

  const response = await MasterServices.uploadFiles(formData, onUploadProgress);

  if (
    !response.data.data ||
    !response.data.data.names ||
    !response.data.data.names.length
  )
    return [];

  return response.data.data.names;
}

export function getTrueOrFalse(value) {
  if (!value || (typeof value === 'string' && ['no', 'n', '0', 'false'].includes(value.toLowerCase()))) {
      return false
  }
  return true
}

export function humanize(str) {
  let i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

export function downloadFromURL(url, filename = "file", extension = "xlsx") {
  var downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = `${filename}.${extension}`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function getNamesById(id_array, item_name) {
  if (!id_array) return;
  let item_objects = this.$store.state.master[item_name].filter((item) =>
    id_array.includes(item.lbl_id)
  );

  let item_names = item_objects.map((item) => item.lbl_name).join(", ");

  return item_names;
}

export function getTreeNamesById(id_array, item_name) {
  let item_objects = this.$store.state.master[item_name].filter((item) =>
    id_array.includes(item.id)
  );

  let item_names = item_objects.map((item) => item.label).join(", ");

  return item_names;
}

export function getTreeLabelsById(ids, treedata) {
  const labelsArr = []

  for (const t of treedata) {
    if (ids.includes(t.id)) {
      labelsArr.push(t.label)
      continue
    }
    
    if (!Array.isArray(t.children) || !t.children.length) { continue }

    for (const c of t.children) {
      if (ids.includes(c.id)) {
        labelsArr.push(c.label)
        break
      }
    }
    
  }

  return labelsArr.join(',');
}

export function formatCurrency(val) {
  try {
    return Number(val)
      .toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replaceAll(",", "'");
  } catch (error) {
    console.error("Error in formatCurrency");
    return "";
  }
}

export function getStrOneOrZero(item) {

  if (item && item !== '0') {
    return '1'
  }
  return '0'

}

export function getStringOrNull(item) {
  try {
    if (typeof item === "string") {
      return item;
    }

    if (item === null || item === undefined) {
      return null;
    }
    // Arrays are json stringified on pupose for files component in frontend
    if (Array.isArray(item) && Array.isArray(item[0])) {
      return JSON.stringify(item);
    }
    if (item.toString) {
      return item.toString();
    }
    return JSON.stringify(item);
  } catch (error) {
    return null;
  }
}

export function formatDate(date) {
  return moment(date).format('DD-MM-YYYY')
}

export function generatePDF(element_id, pdf_title) {
  const divToConvert = document.getElementById(element_id);

  const divHeight = divToConvert.clientHeight;

  const PageCount = divHeight / 1000;

  html2canvas(divToConvert, { scale: 0.95 }).then((canvas) => {
    const imgData = canvas.toDataURL("image/jpeg");
    const pdf = new jsPDF();

    for (let i = 0; i < PageCount; i++) {
      if (i > 0) {
        pdf.addPage();
      }

      const canvas1 = document.createElement("canvas");
      const context1 = canvas1.getContext("2d");
      const startY = i * 1000;
      const endY = (i + 1) * 1000;
      const partCanvasHeight = endY - startY;
      const canvasWidth = 900;

      canvas1.height = partCanvasHeight;
      canvas1.width = canvasWidth;

      context1.fillStyle = "#fff";
      context1.fillRect(0, 0, canvasWidth, partCanvasHeight);

      context1.drawImage(
        canvas,
        0,
        startY,
        canvasWidth,
        partCanvasHeight,
        0,
        0,
        canvasWidth,
        partCanvasHeight
      );

      const imgData1 = canvas1.toDataURL("image/jpeg", 1.0);

      pdf.addImage(imgData1, "jpeg", 0, 10);
    }
    pdf.save(pdf_title);
  });
}
